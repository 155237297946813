<template>
  <v-container fluid>
    <div id="editStudentPage" class="page">
      <div class="pa-4" v-if="!loading">
        <v-row class="my-0">
          <v-col cols="12">
            <v-skeleton-loader type="chip"></v-skeleton-loader>
            <v-skeleton-loader class="py-2" type="divider"></v-skeleton-loader>
            <v-skeleton-loader type="heading"></v-skeleton-loader>

            <div class="mt-4 border-all pa-4">
              <v-skeleton-loader type="table"></v-skeleton-loader>
            </div>
          </v-col>
        </v-row>
      </div>
      <template v-else>
        <div class="page__header sticky-page-header">
          <Toolbar :breadcrumbs="breadcrumbs" />
        </div>
        <div class="page__body">
          <Subheader :menu="menu" class="sticky-page-tab" />
          <router-view></router-view>
        </div>
      </template>
    </div>
  </v-container>
</template>

<script>
import Toolbar from "../Shared/components/Toolbar/index.vue";
import Subheader from "../Shared/components/Subheader/index.vue";
import API from "@/services";

export default {
  data() {
    return {
      breadcrumbs: [
        { title: "Tələbələr", url: "/students" },
        { title: "", url: "#" },
      ],
      menu: [
        {
          text: "Ümumi",
          disabled: false,
          link: null,
        },
        {
          text: "Müqavilə",
          disabled: false,
          link: null,
        },       
        {
          text: "Sənədlər",
          disabled: false,
          link: null,
        },
      ],
      form: null,
      loading: false,
    };
  },
  components: {
    Toolbar,
    Subheader,
  },
  methods: {
    async checkID() {
      if (this.$route.params.id && this.$route.params.id > 0) {
        this.form = await API.fetchStudent(this.$route.params.id);
        this.breadcrumbs[1].title =
          this.form.firstName + " " + this.form.lastName;
        this.menu[0].link = `/students/${this.$route.params.id}/edit`;
        this.menu[1].link = `/students/${this.$route.params.id}/contracts`;
        this.menu[2].link = `/students/${this.$route.params.id}/documents`;

        this.loading = true;
      }
    },
  },
  created() {
    this.checkID();
  },
};
</script>
<style></style>
